@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add global styles */
@layer base {
  body {
    @apply bg-background;
  }
  
  /* Make default borders black */
  * {
    @apply border-black;
  }
  
  /* Style buttons */
  button {
    @apply border-black text-black hover:bg-black hover:text-white transition-colors duration-200;
  }
}

/* Update any existing component styles to use new color scheme */
.border {
  @apply border-black;
}

/* Remove the gradient override */
/* .bg-gradient-to-r {
  @apply from-white to-gray-50;
} */

/* Add custom tab styles */
@layer components {
  .tabs-list {
    @apply bg-background border border-input;
  }
  
  .tabs-trigger {
    @apply transition-all duration-200 hover:bg-muted data-[state=active]:shadow-none;
  }
}